
import Base from '@/mixins/Base.vue';
import { defineComponent } from 'vue';
import FormsReportTypes from '@/components/FormsReportTypes.vue';
import InfringementCategories from '@/components/InfringementCategories.vue';

export default defineComponent({
    components: { InfringementCategories },
    mixins: [Base],
    emits: ['reload'],
    data() {
        return {
            item: {
                translations: {
                    name: {},
                },
                code: '',
                type: '',
                category: undefined,
            },
        };
    },
    methods: {
        createItem(): void {
            this.post('infringements', {
                ...this.item,
                category_id: this.item.category ? this.item.category.id : null,
                name: this.item.translations.name,
            }).then(() => {
                this.$router.push({ name: 'infringements.list' });
                this.$emit('reload');
            });
        },
    },
});
